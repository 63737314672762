<script>
import { SuawInputGroup, SuawHeading, SuawSection, SuawParagraph, SuawDivider, SuawButton, SuawThumbnail } from "@suaw/suaw-component-library";
import AuthorBio from "@/features/articles/components/AuthorBio.vue";
import AuthorPosts from "@/features/articles/components/AuthorPosts.vue";
import * as db from "../data.js";

export default {
  name: "ArticleFrame",
  components: {
    SuawInputGroup,
    SuawHeading,
    SuawSection,
    SuawParagraph,
    SuawDivider,
    SuawButton,
    SuawThumbnail,
    AuthorBio,
    AuthorPosts
  },
  props: {
    articleId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      articles: db.articles()
    };
  },
  computed: {
    article() {
      return this.articles.filter(a => a.id === this.articleId)[0];
    },
    authorText() {
      return `By <a href="#about">${this.article.author}</a> on ${this.article.posted}`;
    }
  },
  methods: {
    async onClick() {
      try {
        const url = `https://www.shutupwrite.com${this.article.route}`;
        await navigator.clipboard.writeText(url);
        this.$root.$emit("universal-success-message", "Link copied to clipboard successfully!");
      } catch (err) {
        this.$root.$emit("universal-error-message", "Failed to copy link to clipboard.");
      }
    },
    onBack() {
      this.$router.push({ name: "ArticleDiscovery" });
    }
  }
};
</script>

<template>
  <SuawInputGroup v-if="article" field-one-size="0" field-two-size="0" :centered="true" direction="column" group-gap="septuple">
    <SuawSection>
      <SuawButton icon-left="IconCircleChevronLeft" button-text="Back to Articles" type="ghost" class="suaw-button--pull-left" size="medium" @click="onBack" />
      <SuawButton icon-right="IconShareOutline" button-text="Share" type="secondary-light" class="suaw-button--pull-right" size="small" @click="onClick" />
    </SuawSection>
    <SuawSection section-size="medium">
      <SuawInputGroup group-style="default" direction="column">
        <SuawHeading level="2" :content="article.heading" />
        <SuawParagraph :text="authorText" />
        <SuawDivider type="inner" color="medium" top-distance="default" bottom-distance="closer" />
      </SuawInputGroup>
    </SuawSection>
    <SuawSection section-size="medium">
      <SuawInputGroup group-style="default" direction="column" group-gap="triple">
        <div>
          <SuawThumbnail size="fill" :content="article.thumbnail" />
        </div>
        <slot></slot>
        <SuawDivider type="inner" color="medium" top-distance="default" bottom-distance="default" />
        <AuthorBio id="bio" :author-id="article.authorId" />
      </SuawInputGroup>
    </SuawSection>
    <SuawSection>
      <SuawButton icon-left="IconCircleChevronLeft" button-text="Back to Articles" type="ghost" class="suaw-button--pull-left" size="medium" @click="onBack" />
      <SuawButton icon-right="IconShareOutline" button-text="Share" type="secondary-light" class="suaw-button--pull-right" size="small" @click="onClick" />
    </SuawSection>
    <SuawDivider type="inner" color="medium" top-distance="closer" bottom-distance="closer" />
    <AuthorPosts id="about" :author-id="article.authorId" />
  </SuawInputGroup>
</template>
