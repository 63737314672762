<script>
import { SuawSection, SuawCarouselColumns } from "@suaw/suaw-component-library";
import * as db from "../data.js";

export default {
  name: "AuthorPosts",
  components: {
    SuawSection,
    SuawCarouselColumns
  },
  props: {
    authorId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      authors: db.authors(),
      articlesAll: db.articles()
    };
  },
  computed: {
    author() {
      return this.authors.filter(a => a.id === this.authorId)[0];
    },
    title() {
      return `Published by ${this.author.name}`;
    },
    bio() {
      return this.author.bio;
    },
    more() {
      return `More Posts by ${this.author.name}`;
    },
    articles() {
      return this.articlesAll.filter(article => this.author.articles.includes(article.id));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawSection>
    <SuawCarouselColumns :carousel-heading="more" card-type="CardArticle" :cards="articles" :cards-per-page="4" @click="onClicked" />
  </SuawSection>
</template>
